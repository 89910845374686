<template>
  <div class="container">
    <p>Extrato Mensal Inter</p>
    <div id="buttonDiv" v-if="!credential" class="btn"></div>
  </div>
</template>
<script>
import { gdrive } from "@/store/gapi.js"
export default {
  name: "SignIn",
  components: {},
  data() {
    return {};
  },
  computed: {
    isReady() {
      return this.$store.getters["gapi/isReady"];
    },
    credential() {
      return this.$store.getters["gapi/credential"];
    },
    interactiveLogin() {
      return this.$store.getters["gapi/interactiveLogin"];
    }
  },
  watch: {
    interactiveLogin(n) {
      if (!n) return;
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
    },
    credential(n, o) {
      if (!o && n) {
        this.$router.push(this.$route.query.redirect || "/");
      }
    },
  }
};
</script>

<style scoped></style>
