<template>
  <div class="text-center mt-5 pt-5" v-if="busy">
    <div class="spinner-border text-warning" role="status"></div>
    <div>Solicitando</div>
    <div>{{ info }}</div>
    <div>Você irá receber o arquivo por email</div>
  </div>
  <div class="container pt-4" v-else>
    <h6>Período</h6>
    <div class="grid-panel grid-year mb-1">
      <div class="grid-body">
        <div class="grid-item">
          <div @click.stop.prevent="year = year - 1">
            <i class="bi bi-chevron-double-left"></i>
          </div>
        </div>
        <div class="grid-item btn btn-default">
          <div class="bold">
            {{ year }}
          </div>
        </div>
        <div class="grid-item">
          <div @click.stop.prevent="year = year + 1">
            <i class="bi bi-chevron-double-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-panel grid-month mb-4">
      <div class="grid-body">
        <div
          class="grid-item btn"
          v-for="(item, ix) in monthList"
          :key="ix"
          :class="{ 'btn-primary': monthIndex == ix }"
          @click.stop.prevent="monthIndex = ix"
        >
          <div class="">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <h6 class="mt-4">Conta Corrente</h6>

    <div class="row">
      <div class="col">
        <input type="number" class="form-control" v-model="account_number" />
      </div>
      <div class="col text-center">
        <button
          class="btn active w-100"
          :class="isValid ? 'btn-success' : 'btn-default'"
          :disabled="!isValid"
          @click.stop.prevent="post"
        >
          Solicitar
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      busy: false,
      monthList: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      monthIndex: (new Date()).getMonth(),
      year: (new Date()).getFullYear(),
      account_number: ""
    }
  },
  computed: {
    info() {
      return `${this.account_number} - ${this.monthList[this.monthIndex]}/${this.year}`
    },
    isValid() {
      return this.account_number && this.account_number.length >= 5
    }
  },
  methods: {
    post() {
      this.busy = true;
      setTimeout(() => {
        this.busy = false;
      }, 2000, this);
    }
  }
}
</script>

<style scoped>
.grid-panel {
  position: relative;
  display: inline-block;
  width: 80%;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 8%;
}

.grid-body {
  display: grid;
  position: absolute;
  gap: 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-item {
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-year {
  height: 40px;
}
.grid-year > .grid-body {
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
  grid-template-rows: 100%;
}

.grid-month {
  height: 180px;
}

.grid-month > .grid-body {
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
  grid-template-rows: 25% 25% 25% 25%;
}
</style>
